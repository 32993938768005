import React from "react";
import {Carousel } from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import './HeaderWebApp.css';

const HeaderCRM = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '100%', height: '10%' }}>  
      <img  
      className="d-block w-100"  
      src="./assets/Smaller/MobileApp_smaller.png" 
      alt="Third slide"  
    />  
     <Carousel.Caption className="carousel-caption-New">  
      <h1 className="font-cursol">CRM</h1>  
      <p className="font-cursol-bottom">CRM helps business to improve productivity and enhance customer experience.</p>  
    </Carousel.Caption>  
  </div>  
    </>
       
  );
};

export default HeaderCRM;
