import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

import { Home, AboutPage, ContactPage, ProjectPage, Login, LoginButton, Register, PageNotFound, BusinessSupport, Failure, WebApplication, HomeAutomation, MobileApp, CRM, ECommerce } from "./pages"

import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
  domain="dev-8npywf3xhsejob3t.us.auth0.com"
  //clientId="a8jXRRHUwo7DHW8UfyjipQvySsNT9udC" // SundarPrint Production
  clientId="qfXBUzWjcXJalQAYJnh75JYLPQoAVaoN" // React_E-Commerce Main Test
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
 >
  <HashRouter>
     <Provider store={store}>
       <Routes>
        <Route path="/" element={<Home />} />
      
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/BusinessSupport" element={<BusinessSupport />} />
       
        <Route path="/login" element={<Login />} />
        <Route path="/LoginButton" element={<LoginButton />} />
        <Route path="/register" element={<Register />} />
  
        <Route path="/WebApplication" element={<WebApplication />} />
        <Route path="/HomeAutomation" element={<HomeAutomation />} />
        <Route path="/MobileApp" element={<MobileApp />} />
        <Route path="/CRM" element={<CRM />} />
        <Route path="/ECommerce" element={<ECommerce />} />
        <Route path="/Failure" element={<Failure />} />
      
        <Route path="*" element={<PageNotFound />} />
      
      </Routes>
    </Provider>
  </HashRouter>
  </Auth0Provider>
);