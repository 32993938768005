import React from "react";
import { Container, Row, Col, Button, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import LaunchIcon from '../images/Launch.png';
import MigrationIcon from '../images/Migration.png';
import SupportIcon from '../images/Support.png';
import { Footer, Navbar } from ".";
import HeaderECommerce from './HeaderECommerce';

const ECommerce = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <Navbar />
     <HeaderECommerce/>
     <div className="container my-8 py-3">

     <p className="font-caption">Bairagi Consultants as an Ecommerce Development Company</p>
     <p className="paragraph_text">An e-commerce platform is a comprehensive software that allows companies to manage all operations related to online sales of products or services. E-commerce platforms create a centralized, digital hub for product and customer data, allowing e-commerce businesses to manage product information, personalize store content and layout, and process online transactions and payments. </p>    
    
     </div>
     <div className="container my-3 py-3">
     <p class="font-semicaption">We Cover Three Common Situations </p>
     <p className="font-semicaption" >Launch </p>
     <div className="container my-3 py-3">
     <img  
      className="w-80 h-50"  
      src={LaunchIcon}
      alt="LaunchIcon"  
    />
    </div>
     <p className="lead text-start" >Guided by your business vision, we plan and build a webstore that performs all the functions you need and works faultlessly. Also, we appeal to our experience and creative thinking to contribute ideas of how to best sell your products and convert your target audience.</p>
    </div>
    <div className="container my-3 py-3">
     <p className="font-semicaption" >Migration </p>
     <div className="container my-3 py-3">
     <img  
      className="w-80 h-80"  
      src={MigrationIcon}
      alt="LaunchIcon"  
    />
    </div>
     <p className="lead text-start" >By migrating your business to a new digital home, we don’t only overcome your current technical limitations or inefficiencies (like cumbersome customization or unreasonably high maintenance cost). We also spot improvement opportunities in design and functionality to strengthen your ecommerce presence..</p>
    </div>
    <div className="container my-3 py-3">
    <p className="font-semicaption" >Support </p>
    <div className="container my-3 py-3">
     <img  
      className="w-80 h-80"  
      src={SupportIcon}
      alt="LaunchIcon"  
    />
    </div>
     <p className="lead text-start" >This is a catchall term for any kind of service you need for a webstore that is already live on the market. The scope and duration of work are up to you – from one-time troubleshooting to ad hoc or continuous cooperation.</p>
    </div>
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
       </div>
    <Footer />
  </>
    
     
  );
};

export default ECommerce;
