import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import {Routes, Route, useNavigate} from 'react-router-dom';
//import toastr from 'cogo-toast';
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

const InitiateLogIn=()=>{

  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
  
}

const handlePassword=async(evt, param)=>{
  
  setPassword(param);
  console.log("Entered Password= " + param);


}
const handleEmail=async(evt, param)=>{

  setEmail(param);
  console.log("Entered Email= " + param);

}

 return (
    <>
      <Navbar />
      <div className="container bg-primary text-white my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="my-3">
                <label htmlFor="display-4">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  name="email"  
                  value={email} 
                  onChange={event=>handleEmail(event, event.target.value)}
                />
              </div>
              <div className="my-3">
                <label htmlFor="floatingPassword display-4">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="password"
                  name="password"  
                  value={password} 
                  onChange={event=>handlePassword(event, event.target.value)}
                />
              </div>
              <div className="my-3">
                <p>New Here? <Link to="/register" className="text-decoration-underline text-info">Register</Link> </p>
              </div>
              <div className="text-center">
              <button className="my-2 mx-auto btn btn-secondary" type="submit" onClick={InitiateLogIn}>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
