import React from "react";
import { Container, Row, Col, Button, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import StandardImage from '../images/Team_1.PNG';
import HeaderWebApp from "./HeaderWebApp";

import './WebApplication.css';

import { Footer, Navbar } from "../components";

const WebApplication = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <Navbar />
     <HeaderWebApp/>
     <p className="font-caption">Advanced and Scalable Architecture.</p>
     <p className="paragraph_text">The web application architecture is the backbone of these background processes. In this article, we will delve into the concept of web application architecture, including its components, diagram, function, and tips for creating an effective architecture for your web app.</p>
     <p className="paragraph_text">A well-designed web application architecture is essential for the performance, scalability, and maintainability of a web application. It helps to create an application that can easily adapt to changing business needs, handle high levels of traffic, and provide a seamless user experience</p>    
     <p className="font-caption">Front-end Technology.</p>
     <p className="paragraph_text">A well-designed web application architecture is essential for the performance, scalability, and maintainability of a web application. It helps to create an application that can easily adapt to changing business needs, handle high levels of traffic, and provide a seamless user experience</p>    
     <p className="paragraph_text">Responsive UI with React.JS, Bootstrap, Angular.JS etc..</p>    
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/front-end-technologies.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
    <p className="font-caption">Back-end Technology.</p>
    <p className="paragraph_text">Today, there is a myriad of tools and technologies available for backend development, such as backend frameworks, databases, programming languages, and so on. Each technology, however, has specific benefits that need to perfectly match your project objectives.</p> 
    <p className="paragraph_text">Technologies being used as backend development are Java, Node.JS, Python, PHP Laravel etc.</p> 
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/back-end-technologies.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
    <p className="font-caption">Use of API.</p>
    <p className="paragraph_text">In the fast-paced and ever-evolving world of web development, Application Programming Interfaces (APIs) have become indispensable. APIs are the backbone of modern web applications, enabling seamless communication between different software systems..</p> 
    <p className="paragraph_text">Web APIs: Allow communication between web servers and browsers or other web servers. Examples include RESTful APIs and GraphQL APIs..</p> 
    <p className="paragraph_text">Supported Technology in Web APIs: Springboot REST Service and Node.js deployed in dedicated Virtual Machine (VM) running in Ubuntu.</p> 
    <p className="paragraph_text">Third-Party APIs: Provided by external services, such as payment gateways (Stripe), social media platforms (Facebook, Twitter), and cloud services (AWS, Google Cloud)..</p> 
    <p className="paragraph_text">Internal APIs: Used within an organization to connect different systems and services.</p> 
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{align:'center', width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/API.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
    <Footer />
  </>
    
     
  );
};

export default WebApplication;
