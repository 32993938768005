import React from "react";
import { Container, Row, Col, Button, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import StandardImage from '../images/Team_1.PNG';
import { Footer, Navbar } from ".";
import HeaderCRM from './HeaderCRM';

const CRM = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <Navbar />
     <HeaderCRM/>
     <p className="font-caption">CRM Software.</p>
     <p className="paragraph_text">CRM </p>    
   
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/react-native.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
    <p className="font-caption">Back-end Technology.</p>
    <p className="paragraph_text">Technologies being used as backend development are Java, Node.JS, Python, PHP Laravel etc.</p> 
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/Android.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
     
     <Footer />
  </>
    
     
  );
};

export default CRM;
