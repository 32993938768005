import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Footer, Navbar } from "../components";


export default function ProjectPage()  {

  
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

    const form = useRef();
    //console.log("Ln 7 ContactUs called.. ");
    const sendEmail = (e) => {
      e.preventDefault();
       
    };


  

   return (
    
      <>
         <Navbar />
          <div className="container bg-primary text-white my-3 py-3">
          <h1 className="text-center">Projects Dashboard</h1>
          <hr />

          <div className="row my-3">
          <div className="col-4 mx-auto">
         
          <hr />
          </div>

          <div className="col-4 mx-auto">
            <hr />
                    </div>
                    </div>
                    </div>
                    <Footer/>
      </>
    );
  };