import React from 'react'
import { Footer, Navbar } from "../components";



const Failure = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Failure...</h1>
        <hr />
        <p className="lead text-center">
        Failure... Sorry Payment Failed. Contact support team..
        </p>
      </div>
      <Footer />
    </>
  )
}

export default Failure